import { useSearchParams, useNavigate } from 'react-router-dom';
import { selfcareLogin, getAuthProfilo, manageError, redirect } from '../api/api';
import {useEffect} from 'react';
import { LoginProps, ManageErrorResponse } from '../types/typesGeneral';
import { getDatiModuloCommessa } from '../api/apiSelfcare/moduloCommessaSE/api';
import { PathPf } from '../types/enum';

// Blank page utilizzata per l'accesso degli utenti tramite  Selfcare

/*quando l'utente SELFCARE va al link https://uat.selfcare.pagopa.it/auth/login , procede con login es. (comune di Erba ) , viene fatto un redirect
automatico su questa ('/auth?selfcareToken=token') blank page dove , se c'è un profilo già presente nella LOCAL STORAGE lo andiamo ad eliminare , prendiamo il token che selfcare ci inserisce nell'url ,
e andiamo a fare la prima chiamata getSelfcare che ci restituisce un secondo token che noi utilizziamo nella seconda chiamata getProfilo.
Nella risposta della chiamata getProfilo noi andiamo ad estrapolare il jwt, salvarlo nella LOCAL STORAGE, così da poterlo utilizzare in ogni chiamata 
da parte dell'utente SELFCARE

*/
const Auth : React.FC<LoginProps> = ({dispatchMainState}) =>{
    
    const handleModifyMainState = (valueObj) => {
        dispatchMainState({
            type:'MODIFY_MAIN_STATE',
            value:valueObj
        });
    };
  
    const [searchParams] = useSearchParams();
    const token = searchParams.get('selfcareToken');

    const navigate = useNavigate();

type  Jwt = {
    jwt:string
}
interface ParameterGetProfilo {
    data:Jwt[]
}

// terza chiamata fatta per verificare lo stato della commessa e eseguire azioni diverse a seconda del risultato 
const getCommessa = async (tokenC, nonceC,infoProfilo) =>{

    await getDatiModuloCommessa(tokenC, nonceC).then((res)=>{

        if(res.data.modifica === true && res.data.moduliCommessa.length === 0 ){
          
            localStorage.setItem('statusApplication',JSON.stringify({
                inserisciModificaCommessa:'INSERT',
                statusPageInserimentoCommessa:'mutable',
                primoInserimetoCommessa:true
            
            }));
            // ci sono commesse inserite nel mese corrente e posso modificarle
        }else if(res.data.modifica === true && res.data.moduliCommessa.length > 0){

            localStorage.setItem('statusApplication',JSON.stringify({
                inserisciModificaCommessa:'MODIFY',
                statusPageInserimentoCommessa:'immutable',
                primoInserimetoCommessa:false
            }));
    
        }else if(res.data.modifica === false ){

            localStorage.setItem('statusApplication',JSON.stringify({
                inserisciModificaCommessa:'NO_ACTION',
                statusPageInserimentoCommessa:'immutable',
                primoInserimetoCommessa:false
            }));
        }
        const getProfilo = localStorage.getItem('profilo') || '{}';
        const profilo =  JSON.parse(getProfilo);
        const newProfilo = {...profilo, ...{idTipoContratto:res.data.idTipoContratto}};

        const string = JSON.stringify(newProfilo);
        localStorage.setItem('profilo', string);
        handleModifyMainState(infoProfilo);
        navigate(PathPf.DATI_FATTURAZIONE);
    }).catch((err)=>{
        manageError(err,dispatchMainState);
        
    });
};

//  seconda chiamata
const getProfilo = async (res:ParameterGetProfilo)=>{
      
    await getAuthProfilo(res.data[0].jwt)
        .then(resp =>{
            const storeProfilo = resp.data;
            localStorage.setItem('profilo', JSON.stringify({
                auth:storeProfilo.auth,
                nomeEnte:storeProfilo.nomeEnte,
                descrizioneRuolo:storeProfilo.descrizioneRuolo,
                ruolo:storeProfilo.ruolo,
                dataUltimo:storeProfilo.dataUltimo,
                dataPrimo:storeProfilo.dataPrimo,
                prodotto:storeProfilo.prodotto,
                jwt:res.data[0].jwt,
                profilo:storeProfilo.profilo, // profilo utilizzato per la gestione delle notifiche/contestazioni
                nonce:storeProfilo.nonce
            }));
           
            if(resp.data.profilo === "REC" || resp.data.profilo === "CON"){
                localStorage.removeItem("statusApplication");
                handleModifyMainState({ruolo:resp.data.ruolo,nonce:storeProfilo.nonce,authenticated:true});
                navigate(PathPf.LISTA_NOTIFICHE);
            }else{
                const infoProfilo = {ruolo:resp.data.ruolo,nonce:storeProfilo.nonce,authenticated:true};
                getCommessa(res.data[0].jwt, storeProfilo.nonce,infoProfilo);
                //setCheckProfilo(true);
                // setto il ruolo nello state di riferimento globale
               
                
            }
        } )
        .catch(() => {
            window.location.href = redirect;
        });
};
 
// prima chiamata 
const getSelfcare = async() =>{
    await selfcareLogin(token).then(res =>{
        localStorage.clear();

        if(res.status === 200){
            // store del token nella local storage per tutte le successive chiamate START
            const storeJwt = {token:res.data[0].jwt};
            localStorage.setItem('token', JSON.stringify(storeJwt));
           
            // store del token nella local storage per tutte le successive chiamate END
            getProfilo(res);
               
        }
    }).catch((err:ManageErrorResponse) =>{
        window.location.href = redirect;
        //manageError(err, navigate);
    });
};

useEffect(()=>{
  
    getSelfcare();
},[]);
   
return (
    <></>
);
};

export default Auth;